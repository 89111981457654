@import 'react-redux-toastr/src/styles/index';

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

ol,
ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

a {
  color: inherit;
  text-decoration: none;
  &:hover {
    text-decoration: none;
  }
}

body {
  font-size: 16px;
  font-family: 'Arial', sans-serif;
}

/*Глобальные стили слайдера slick*/
#__next .slick-initialized {
  opacity: 0;
  animation: fadeIn ease-in 1;
  animation-duration: 0.4s;
  animation-fill-mode: forwards;
  overflow: hidden;
  position: static;
}
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
    visibility: visible;
  }
}
#__next .slick-slide {
  width: min-content;
  width: -moz-min-content;
  margin: 0 10px;
}
#__next .slick-next,
#__next .slick-prev {
  position: absolute;
  display: block;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  cursor: pointer;
  z-index: 99;
  line-height: 0;
  font-size: 0;
  padding: 0;
  border: none;
  top: 130px;
  background: linear-gradient(0deg, rgba(241, 144, 1, 1) 0%, rgba(247, 224, 118, 1) 100%);
  box-shadow: 0 4px 3px rgba(0, 0, 0, 0.25);
  color: transparent;
}
#__next .slick-prev {
  left: -17px;
}
#__next .slick-next {
  right: -17px;
}
#__next .slick-prev:after,
#__next .slick-next:after {
  content: '';
  position: absolute;
  top: 50%;
  left: 52%;
  margin-right: -50%;
  transform: translate(-50%, -50%);
  width: 10px;
  height: 16px;
  background: url('/icons/arrow-black.svg') no-repeat 0 0;
  background-size: cover;
}
#__next .slick-prev:after {
  left: 48%;
  transform: translate(-50%, -50%) scale(-1, 1);
}
#__next .slick-prev:before,
#__next .slick-next:before {
  color: transparent;
}
#__next .slick-list {
  width: max-content;
}

//Cлайдер в блоке отзывы
#__next #__quotesSlider {
  .slick-prev,
  .slick-next {
    top: 50%;
  }
  .slick-slide {
    width: 100%;
    margin: 0;
  }
  .slick-track {
    display: flex !important;
    align-items: center !important;
  }
  .slick-list {
    width: auto;
  }
  .slick-dots {
    bottom: -5px;
    text-align: left;
  }

  .slick-dots li {
    width: 8px;
    height: 8px;
    margin: 0 3px;
  }

  .slick-dots li button:before {
    content: '';
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: #979797;
  }
}

@media screen and (max-width: 530px) {
  #__next .slick-slide {
    margin: 0 5px;
  }
}

/*Стилизация скроллбара*/
/* Firefox */
.scrollbar {
  scrollbar-width: thin;
  scrollbar-color: #c5c5c5 #f4f4f4;
}

/* Chrome, Edge и Safari */
#__next div[data-scrollbar='sidebar']::-webkit-scrollbar {
  width: 6px;
}

#__next div[data-scrollbar='sidebar']::-webkit-scrollbar-track {
  background: #ededed;
  border-radius: 4px;
}

#__next div[data-scrollbar='sidebar']::-webkit-scrollbar-thumb {
  background-color: #b7b7b7;
  border-radius: 20px;
}

.titleH2 {
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 15px;
  padding: 0;
  line-height: 1.3;
}

/* Кнопки */
.bookSmallBtn {
  position: relative;
  box-sizing: border-box;
  display: inline-block;
  font-size: 16px;
  color: #000;
  padding: 9px 14px 11px;
  background: linear-gradient(180deg, #f7e076 0%, #f19001 100%);
  border-radius: 5px;
  overflow: hidden;
  z-index: 1;
  border: none;
  cursor: pointer;
  &_paddingSmall {
    padding: 5px 14px 6px;
  }
}

/* Chrome, Edge и Safari */
#__next div[data-scrollbar='styles']::-webkit-scrollbar {
  height: 8px;
}

#__next div[data-scrollbar='styles']::-webkit-scrollbar-track {
  background: #f4f4f4;
  border-radius: 4px;
}

#__next div[data-scrollbar='styles']::-webkit-scrollbar-thumb {
  background-color: #c5c5c5;
  border-radius: 20px;
}

#nprogress .bar {
  border-bottom: 2px solid rgba(255, 255, 255, 0.2);
}

//Cтилизация аудиоплеера
#audio_player {
  .rhap_additional-controls {
    display: none;
  }
  .rhap_time {
    font-size: 14px;
    color: #575757;
  }
  .rhap_container {
    padding: 10px 10px;
  }
}

//Враппкр второго типа карточи
div[data-btn-cards='tiles'] ~ .cardsWrapper {
  margin: 0 -9px 20px -9px;
  display: flex;
  flex-wrap: wrap;
}
